import './index.scss'
import Masonry from 'react-masonry-css'
import React from 'react'
import Swiper from 'react-id-swiper'
import questions from './questions'
import {
  CardQuestion,
  CardQuestionDescription,
  CardQuestionTitle,
  Container,
  HeaderInfoContainer,
  HeaderInfoFirstCol,
  HeaderInfoSecondCol,
  HeaderInfoSecondColParagraph,
  HeaderInfoSecondColParagraphDescription,
  HeaderInfoSecondColParagraphTitle,
  HeaderInfoTitle,
  SessionSVG,
  Wrapper
} from './HowPsychologistHelp.styles'
import { Grid } from '../../atoms/Grid'
import { Title } from '../../atoms/Title'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const paramsCards = {
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  preventClicks: false,
  preventClicksPropagation: false
}

const breakpointColumnsObj = {
  default: 2,
  1023: 2
}

const HowPsychologistHelp = () => {
  const wWidth = useSelector(selectDeviceSize)
  return (
    <Wrapper>
      <section>
        <Grid>
          <Container>
            <HeaderInfoContainer>
              <HeaderInfoFirstCol>
                <HeaderInfoTitle>
                  <Title.H2asH1styles>
                    Чем может помочь психолог?
                  </Title.H2asH1styles>
                </HeaderInfoTitle>
                <SessionSVG />
              </HeaderInfoFirstCol>
              <HeaderInfoSecondCol>
                <HeaderInfoSecondColParagraph>
                  <HeaderInfoSecondColParagraphTitle>
                    Понять почему
                  </HeaderInfoSecondColParagraphTitle>
                  <HeaderInfoSecondColParagraphDescription>
                    {/* eslint-disable-next-line max-len */}
                    Психолог поможет разобраться в причинах и триггерах ваших
                    панических атак. Понимание того, как они устроены — первый,
                    но очень значимый шаг к избавлению от них.
                  </HeaderInfoSecondColParagraphDescription>
                </HeaderInfoSecondColParagraph>
                <HeaderInfoSecondColParagraph>
                  <HeaderInfoSecondColParagraphTitle>
                    Поможет справиться
                  </HeaderInfoSecondColParagraphTitle>
                  <HeaderInfoSecondColParagraphDescription>
                    {/* eslint-disable-next-line max-len */}
                    Панические атаки нередко оставляют за собой другие
                    ментальные последствия. Например, избегание мест, в которых
                    они происходили, интенсивный страх определенных мест или
                    ситуаций. Психолог поможет справиться с этими последствиями
                    и улучшить качество жизни.
                  </HeaderInfoSecondColParagraphDescription>
                </HeaderInfoSecondColParagraph>
                <HeaderInfoSecondColParagraph>
                  <HeaderInfoSecondColParagraphTitle>
                    Найдет способы
                  </HeaderInfoSecondColParagraphTitle>
                  <HeaderInfoSecondColParagraphDescription>
                    {/* eslint-disable-next-line max-len */}
                    Вместе со специалистом вы сможете найти только те способы
                    совладания с тревогой, которые подходят и комфортны вам.
                  </HeaderInfoSecondColParagraphDescription>
                </HeaderInfoSecondColParagraph>
              </HeaderInfoSecondCol>
            </HeaderInfoContainer>
            {wWidth > 767 ? (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="question-masonry-grid"
                columnClassName="question-masonry-grid_column"
              >
                {questions.map((question) => (
                  <CardQuestion>
                    <CardQuestionTitle>{question.title}</CardQuestionTitle>
                    <CardQuestionDescription>
                      {question.description}
                    </CardQuestionDescription>
                  </CardQuestion>
                ))}
              </Masonry>
            ) : (
              <Swiper {...paramsCards}>
                {questions.map((question) => (
                  <CardQuestion>
                    <CardQuestionTitle>{question.title}</CardQuestionTitle>
                    <CardQuestionDescription>
                      {question.description}
                    </CardQuestionDescription>
                  </CardQuestion>
                ))}
              </Swiper>
            )}
          </Container>
        </Grid>
      </section>
    </Wrapper>
  )
}

export default HowPsychologistHelp
