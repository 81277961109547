import React from 'react'
import {
  Container,
  FirstCol,
  NumbersInfoContainer,
  NumbersInfoDescription,
  NumbersInfoTitle,
  ParagraphContainer,
  ParagraphImgWrapper,
  ParagraphTextWrapper,
  SecondCol,
  StarSVG,
  TitleWrapper,
  Wrapper
} from './WhyHelpPanicAttack.styles'
import { Grid } from '../../atoms/Grid'
import { Title } from '../../atoms/Title'

const WhyHelpPanicAttack = () => (
  <Wrapper>
    <section>
      <Grid>
        <Container>
          <FirstCol>
            <TitleWrapper>
              <Title.H2asH1styles>
                Почему стоит получить психологическую помощь от панических атак
                в YouTalk?
              </Title.H2asH1styles>
            </TitleWrapper>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                {/* eslint-disable-next-line max-len */}
                Наши психологи имеют соответствующее образование и опыт работы с
                паническими атаками и прошли серьезный отбор на компетентность.
              </ParagraphTextWrapper>
            </ParagraphContainer>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                {/* eslint-disable-next-line max-len */}С помощью YouTalk вы
                можете получать консультации из любого места и в любое время. У
                нас есть психологи из разных часовых поясов, поэтому вы сможете
                найти специалиста, проводящего консультации в удобное для вас
                время.
              </ParagraphTextWrapper>
            </ParagraphContainer>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                {/* eslint-disable-next-line max-len */}
                Консультации в YouTalk анонимны. Нам важен лишь ваш контакт для
                связи с психологом и администратором. Всю остальную личную
                информацию вы можете оставить при себе, если хотите.
              </ParagraphTextWrapper>
            </ParagraphContainer>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                {/* eslint-disable-next-line max-len */}
                Точный подбор. Наши специалисты по подбору — квалифицированные
                психологи. Они уточнят ваш запрос и подберут специалиста,
                опираясь на десятки критериев. В YouTalk 97% клиентов находят
                своего психолога с первого раза.
              </ParagraphTextWrapper>
            </ParagraphContainer>
          </FirstCol>
          <SecondCol>
            <NumbersInfoContainer>
              <NumbersInfoTitle>114</NumbersInfoTitle>
              <NumbersInfoDescription>
                психологов, специализирующихся на панических атаках
              </NumbersInfoDescription>
            </NumbersInfoContainer>
            <NumbersInfoContainer>
              <NumbersInfoTitle>{'>'}2&nbsp;500</NumbersInfoTitle>
              <NumbersInfoDescription>
                людей обратились к нам с запросом на помощь с тревожностью
              </NumbersInfoDescription>
            </NumbersInfoContainer>
            <NumbersInfoContainer>
              <NumbersInfoTitle>15&nbsp;500</NumbersInfoTitle>
              <NumbersInfoDescription>
                онлайн-консультаций, посвященных работе над тревогой YouTalk
                провели по всему миру
              </NumbersInfoDescription>
            </NumbersInfoContainer>
          </SecondCol>
        </Container>
      </Grid>
    </section>
  </Wrapper>
)

export default WhyHelpPanicAttack
