import React from 'react'
import starSVG from './img/star.svg'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import {
  ButtonWrapper,
  Content,
  IconWrapper,
  TextWrapper,
  Wrapper
} from './InfoPanicAttack.styles'
import { Grid } from '../../atoms/Grid'

export const StarSVG = styled.img.attrs(() => ({
  src: starSVG
}))``

const InfoPanicAttack = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Content>
            <IconWrapper>
              <StarSVG />
            </IconWrapper>
            <TextWrapper>
              {/* eslint-disable-next-line max-len */}
              Панические атаки могут быть ужасающими, а страх испытать её ещё
              раз может вызывать избегание мест или ситуаций, в которых
              предыдущие панические атаки возникали, и снижению качества жизни.
            </TextWrapper>
          </Content>
          <ButtonWrapper>
            <Button.NewPrimary href="/wizard/" size="large" type="link">
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonWrapper>
        </Grid>
      </section>
    </Wrapper>
  )
}

export default InfoPanicAttack
