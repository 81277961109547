import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { size } from '../../constants'

export const Wrapper = styled.div`
  background: white;
  position: relative;
  padding-bottom: 48px;

  &::before {
    content: '';
    position: absolute;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    background: rgb(230, 240, 253);
    width: calc(100% + 64px);
    transform: translateX(-32px) rotate(180deg);
    height: 30px;
    top: -2px;
    z-index: 0;
  }
`

export const Content = styled.div`
  width: 792px;
  padding: 40px 16px;
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;
  margin: 32px auto;

  @media (max-width: ${size.lg}) {
    width: 632px;
    padding: 40px 24px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
    padding: 40px 16px;
  }
`

export const TextWrapper = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #333;
  text-align: center;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const IconWrapper = styled.div`
  text-align: center;
  margin-top: -110px;
  margin-bottom: 8px;
`

export const ButtonWrapper = styled.div`
  ${Button.NewPrimary} {
    margin: auto;

    @media (max-width: ${size.xs}) {
      width: 100%;
    }
  }
`
