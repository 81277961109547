import React from 'react'
import {
  BackgroundWrapper,
  ButtonsWrapper,
  Content,
  DescriptionWrapper,
  InfoContainer,
  TitleWrapper,
  Wrapper
} from './PanicAttackHeader.styles'
import { BreadCrumbs } from '../../molecules/BreadCrumbs'
import { Button } from '../../atoms/Button'
import { Event as GAEvent } from '../../components/GA'
import { Grid } from '../../atoms/Grid'
import { SeoPriceForTitleBlock } from '../../atoms/SeoPriceForTitleBlock'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'

const PanicAttackHeader = () => {
  return (
    <Wrapper>
      <BreadCrumbs fill="#0063A8" />
      <BackgroundWrapper>
        <section>
          <Grid>
            <Content>
              <InfoContainer>
                <TitleWrapper>
                  <Title.H1>
                    Онлайн помощь <br />
                    психолога при <br />
                    панических атаках <br />
                  </Title.H1>
                </TitleWrapper>
                <DescriptionWrapper>
                  <Text.Large semiBold color="#fff">
                    Консультант-диагност с психологическим <br />
                    образованием поможет сделать правильный <br />
                    выбор
                  </Text.Large>
                  <SeoPriceForTitleBlock />
                </DescriptionWrapper>
                <ButtonsWrapper>
                  <Button.NewPrimary
                    href="/wizard/"
                    onClick={() => GAEvent.openNavigate('panicAttackLanding')}
                    size="large"
                    type="link"
                  >
                    Подобрать психолога
                  </Button.NewPrimary>
                </ButtonsWrapper>
              </InfoContainer>
            </Content>
          </Grid>
        </section>
      </BackgroundWrapper>
    </Wrapper>
  )
}
export default PanicAttackHeader
