import React from 'react'
import dizzinessSVG from './img/img03.svg'
import dyspneaSVG from './img/img06.svg'
import fearSVG from './img/img02.svg'
import heartSVG from './img/img09.svg'
import nauseaSVG from './img/img04.svg'
import numbnessSVG from './img/img01.svg'
import painSVG from './img/img05.svg'
import shiverSVG from './img/img07.svg'
import sweatingSVG from './img/img08.svg'
import { ItemSvg } from './SignsPanicAttack.styles'

const signsItems = [
  {
    title: 'Быстрое сердцебиение',
    img: <ItemSvg alt="Быстрое сердцебиение" src={heartSVG} />
  },
  {
    title: 'Повышенное потоотделение',
    img: <ItemSvg alt="Быстрое сердцебиение" src={sweatingSVG} />
  },
  {
    title: 'Встряхивание или дрожь',
    img: <ItemSvg alt="Встряхивание или дрожь" src={shiverSVG} />
  },
  {
    title: 'Одышка или чувство удушья',
    img: <ItemSvg alt="Одышка или чувство удушья" src={dyspneaSVG} />
  },
  {
    title: 'Боль или дискомфорт в груди',
    img: <ItemSvg alt="Боль или дискомфорт в груди" src={painSVG} />
  },
  {
    title: 'Тошнота или расстройство желудка',
    img: <ItemSvg alt="Тошнота или расстройство желудка" src={nauseaSVG} />
  },
  {
    title: 'Головокружение или предобморочное состояние',
    img: (
      <ItemSvg
        alt="Головокружение или предобморочное состояние"
        src={dizzinessSVG}
      />
    )
  },
  {
    title: 'Страх потерять контроль, умереть или сойти с ума',
    img: (
      <ItemSvg
        alt="Страх потерять контроль, умереть или сойти с ума"
        src={fearSVG}
      />
    )
  },
  {
    title: 'Онемение или покалывание',
    img: <ItemSvg alt="Онемение или покалывание" src={numbnessSVG} />
  }
]

export default signsItems
