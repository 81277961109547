import React from 'react'
import signsItems from './SignsItems'
import { Grid } from '../../atoms/Grid'
import {
  SignItem,
  SignItemTitle,
  SignItemsContainer,
  TitleWrapper,
  Wrapper
} from './SignsPanicAttack.styles'
import { Title } from '../../atoms/Title'

const SignsPanicAttack = () => (
  <Wrapper>
    <section>
      <Grid>
        <TitleWrapper>
          <Title.H2asH1styles>Признаки панической атаки:</Title.H2asH1styles>
        </TitleWrapper>
        <SignItemsContainer>
          {signsItems.map((item) => (
            <SignItem>
              {item.img}
              <SignItemTitle>{item.title}</SignItemTitle>
            </SignItem>
          ))}
        </SignItemsContainer>
      </Grid>
    </section>
  </Wrapper>
)

export default SignsPanicAttack
