import styled from 'styled-components'
import { size } from '../../constants'
export const Wrapper = styled.div`
  padding-bottom: 64px;
`

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 56px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 40px;
  }

  @media (max-width: ${size.xs}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${size.xxs}) {
    margin-bottom: 24px;
  }
`

export const ItemSvg = styled.img`
  width: 100%;

  @media (max-width: ${size.sm}) {
    width: 179px;
  }
  @media (max-width: ${size.xs}) {
    width: 218px;
  }
`

export const SignItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 48px;

  @media (max-width: ${size.sm}) {
    row-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${size.xs}) {
    row-gap: 24px;
    grid-template-columns: 1fr;
  }
`

export const SignItem = styled.div`
  text-align: center;
`

export const SignItemTitle = styled.div`
  margin-top: 16px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`
