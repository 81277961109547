import Fire from './img/fire.svg'
import styled from 'styled-components'
import { size } from '../../constants'

export const Wrapper = styled.div`
  margin-bottom: 64px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 40px;
  }
`

export const Container = styled.div`
  margin: auto;
  width: 792px;
  padding: 40px 32px;
  display: flex;
  gap: 16px;
  background: white;
  border-radius: 24px;
  border: 1px solid #dedede;
  margin-top: -146px;

  @media (max-width: ${size.lg}) {
    width: 632px;
  }

  @media (max-width: ${size.md}) {
    width: 604px;
    margin-top: -56px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
    padding: 24px;
    flex-direction: column;
  }
`

export const TitleWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  color: #0063a8;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`

export const FireSvg = styled.img.attrs(() => ({
  src: Fire
}))`
  margin-bottom: 8px;
`

export const TextWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #333;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`
