import anxietySVG from './img/anxiety.svg'
import breatheSVG from './img/breathe.svg'
import groundingSVG from './img/grounding.svg'
import preparationSVG from './img/preparation.svg'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { size } from '../../constants'

export const Wrapper = styled.div`
  background: white;
  padding-bottom: 72px;

  @media (max-width: ${size.md}) {
    padding-bottom: 56px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 48px;
  }

  @media (max-width: ${size.xs}) {
    padding-bottom: 110px;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 56px;

  @media (max-width: ${size.md}) {
    margin-bottom: 48px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 24px;
  }
`

export const InfoCard = styled.div`
  padding: 24px;
  border-radius: 32px;
  background: #f9fdfd;

  @media (max-width: ${size.md}) {
    display: flex;
    gap: 16px;
  }

  @media (max-width: ${size.sm}) {
    display: block;
  }
`

export const InfoCardFullSize = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  padding: 24px;
  display: flex;
  border-radius: 32px;
  background: #f9fdfd;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    display: block;
  }
`

export const InfoCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  padding-bottom: 40px;

  @media (max-width: ${size.md}) {
    display: block;
  }
`

export const BreatheSVG = styled.img.attrs(() => ({
  src: breatheSVG
}))`
  width: 100%;
`

export const AnxietySVG = styled.img.attrs(() => ({
  src: anxietySVG
}))`
  width: 100%;
`

export const GroundingSVG = styled.img.attrs(() => ({
  src: groundingSVG
}))`
  width: 100%;
`

export const PreparationSVG = styled.img.attrs(() => ({
  src: preparationSVG
}))`
  width: 100%;
`

export const InfoCardImageHeader = styled.div`
  text-align: center;
  width: 254px;
  height: 183px;
  margin-bottom: 16px;

  @media (max-width: ${size.md}) {
    width: 208px;
    height: auto;
  }

  @media (max-width: ${size.sm}) {
    margin: auto;
    margin-bottom: 16px;
  }

  @media (max-width: ${size.xs}) {
    width: 156px;
  }
`

export const InfoCardTextContainer = styled.div`
  width: 100%;
`

export const InfoCardTitle = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #333;
  margin-bottom: 8px;
`
export const InfoCardDescription = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #333;
`

export const ButtonContainer = styled.div`
  ${Button.NewPrimary} {
    margin: auto;

    @media (max-width: ${size.xs}) {
      width: 100%;
    }
  }
`
