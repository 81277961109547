import sessionSVG from './img/session.svg'
import styled from 'styled-components'
import { Title } from '../../atoms/Title'
import { color } from '../../../youtalk-storybook/src/ui'
import { size } from '../../constants'

export const Wrapper = styled.div`
  background: white;
  padding-bottom: 72px;

  @media (max-width: ${size.lg}) {
    padding-bottom: 56px;
  }

  @media (max-width: ${size.lg}) {
    padding-bottom: 48px;
  }
  @media (max-width: ${size.sm}) {
    padding-bottom: 0;
    background: #2963a3;
  }
`

export const Container = styled.div`
  border-radius: 40px;
  background: #2963a3;
  padding: 72px 56px;

  @media (max-width: ${size.lg}) {
    padding: 64px 40px;
  }

  @media (max-width: ${size.md}) {
    padding: 40px 32px;
  }

  @media (max-width: ${size.sm}) {
    padding: 40px 0;
    border-radius: 0;
  }

  @media (max-width: ${size.xs}) {
    padding: 32px 0;
  }
`
export const HeaderInfoContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 48px;

  @media (max-width: ${size.md}) {
    display: block;
    margin-bottom: 32px;
  }
`

export const HeaderInfoTitle = styled.div`
  margin-bottom: 47px;

  ${Title.H2asH1styles} {
    color: ${color.text.white}};
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 0;
  }
`

export const HeaderInfoFirstCol = styled.div`
  @media (max-width: ${size.md}) {
    margin-bottom: 32px;
    display: flex;
    column-gap: 16px;
    flex-flow: row-reverse;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
    flex-direction: column;
    flex-flow: column-reverse;
  }
`

export const HeaderInfoSecondCol = styled.div`
  @media (max-width: ${size.md}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const SessionSVG = styled.img.attrs(() => ({
  src: sessionSVG
}))`
  width: 430px;
  height: 255px;

  @media (max-width: ${size.lg}) {
    width: 346px;
    height: 255px;
  }

  @media (max-width: ${size.md}) {
    width: 266px;
    height: 196px;
  }

  @media (max-width: ${size.xs}) {
    width: 133px;
    height: 98.02px;
  }
`

export const HeaderInfoSecondColParagraph = styled.div`
  margin-bottom: 24px;
`

export const HeaderInfoSecondColParagraphTitle = styled.div`
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
`

export const HeaderInfoSecondColParagraphDescription = styled.div`
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

export const CardQuestionsContainer = styled.div`
  margin-right: -20px;
`

export const CardQuestion = styled.div`
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;
  padding: 24px;

  @media (max-width: ${size.sm}) {
    min-width: calc(100% - 40px) !important;
    width: calc(100% - 40px) !important;
    margin-right: 20px;
  }
`

export const CardQuestionTitle = styled.div`
  color: #333;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
`

export const CardQuestionDescription = styled.div`
  color: #333;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`
