import React from 'react'
import {
  Container,
  FireSvg,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './WhatIsPanicAttack.styles'
import { Grid } from '../../atoms/Grid'

const WhatIsPanicAttack = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Container>
            <TitleWrapper>
              <FireSvg />
              <div>Что такое панические атаки?</div>
            </TitleWrapper>
            <TextWrapper>
              Панические атаки — это интенсивные эпизоды страха или
              беспокойства, которые могут длиться от нескольких минут до
              нескольких часов. Они могут быть вызваны определенным событием,
              например, травмирующим опытом, но часто возникают внезапно и могут
              заставить вас чувствовать себя беспомощными.
            </TextWrapper>
          </Container>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default WhatIsPanicAttack
