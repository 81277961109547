import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

export const Wrapper = styled.div`
  background-color: #0063a8;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    background: rgb(230, 240, 253);
    width: calc(100% + 64px);
    transform: translateX(-32px);
    height: 30px;
    bottom: -2px;
    z-index: 0;
  }
`

export const BackgroundWrapper = styled.div`
  background-image: url(/img/panic-person/1440.svg);
  background-repeat: no-repeat;
  background-position: top 106px right 0;
  max-width: 1440px;
  margin: auto;

  @media (max-width: ${size.lg}) {
    background-image: url(/img/panic-person/1024.svg);
    max-width: 1024px;
  }

  @media (max-width: ${size.md}) {
    background-image: url(/img/panic-person/768.svg);
    max-width: 768px;
  }

  @media (max-width: ${size.sm}) {
    background-image: url(/img/panic-person/414.svg);
    max-width: 414px;
    background-position: bottom;
  }

  @media (max-width: ${size.xs}) {
    background-image: url(/img/panic-person/320.svg);
    max-width: 320px;
    background-position: bottom;
  }
`

export const Content = styled.div`
  padding-top: 74px;
  padding-bottom: 193px;
  display: flex;

  @media (max-width: ${size.md}) {
    padding-top: 58px;
    padding-bottom: 135px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 58px;
    padding-bottom: 328px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 58px;
    padding-bottom: 308px;
  }
`

export const TitleWrapper = styled.div`
  ${Title.H1} {
    color: white;
  }

  margin-bottom: 16px;
`

export const DescriptionWrapper = styled.div`
  color: white;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`

export const InfoContainer = styled.div``
