import React from 'react'

const questions = [
  {
    title: 'Какая средняя продолжительность лечения панических атак?',
    description:
      'В некоторых психотерапевтических подходах работа с паническими\n' +
      '                  атаками может занимать всего 2-3 месяца, особенно если вы\n' +
      '                  только начали сталкиваться с ними, и у вас нет других\n' +
      '                  ментальных расстройств.'
  },
  {
    title: 'Какого результата можно ожидать?',
    description:
      'Во многих случаях можно ожидать полного прекращения панических\n' +
      '                  атак или, по крайней мере, уменьшения их количества. Но важно\n' +
      '                  понимать, что результат в том числе зависит от индивидуальных\n' +
      '                  особенностей вашего случая и действий во время терапии.'
  },
  {
    title: 'Когда нужна помощь психолога при панических атаках?',
    description:
      'Обратиться за помощью стоит сразу, как они появились. Потому\n' +
      '                  что учащение приступов и их ухудшение со временем — одна из\n' +
      '                  особенностей панического расстройства. Чем раньше вы\n' +
      '                  обратитесь к специалисту, тем проще будет справиться с\n' +
      '                  паническими атаками.'
  },
  {
    title: 'Что будет, если не лечить панические атаки?',
    description:
      ' Иногда паническое расстройство может пройти самостоятельно или\n' +
      '                  при использовании техник самопомощи. Однако мы не рекомендуем\n' +
      '                  надеяться на подобный исход, так как наиболее часто панические\n' +
      '                  атаки со временем учащаются, случаются в разных местах,\n' +
      '                  провоцируют агорафобию (страх открытых, людных пространств) и\n' +
      '                  депрессию.'
  },
  {
    title: 'В чем преимущества психотерапии панических атак?',
    description:
      'Психотерапия — основной метод лечения панических атак. В\n' +
      '                  процессе работы с психологом вы разберетесь в причинах и\n' +
      '                  триггерах, рассмотрите аспекты, подкрепляющие панические атаки\n' +
      '                  и сможете выработать новые способы снижения тревоги,\n' +
      '                  прерывание панических атак в моменте. Постепенно количество\n' +
      '                  панических атак начнет снижаться вплоть до полного их\n' +
      '                  исчезновения.'
  },
  {
    title: 'Какие методы лечения панических атак применяются?',
    description: (
      <>
        Одним из основных методов лечения панических атак является
        когнитивно-поведенческая терапия, так как эффективность такого лечения
        доказана{' '}
        <a
          href="https://pubmed.ncbi.nlm.nih.gov/27071857/"
          rel="noreferrer"
          target="_blank"
        >
          научными исследованиями
        </a>{' '}
        и составляет всего 7-14 часов терапии. И другие подходы эффективно
        работают с паническим расстройством, просто не подкрепленные большим
        объемом исследований на данный момент.
      </>
    )
  }
]

export default questions
