import React from 'react'
import {
  AnxietySVG,
  BreatheSVG,
  GroundingSVG,
  InfoCard,
  InfoCardDescription,
  InfoCardFullSize,
  InfoCardImageHeader,
  InfoCardTextContainer,
  InfoCardTitle,
  InfoCardsContainer,
  PreparationSVG,
  TitleWrapper,
  Wrapper
} from './WhatDoPanicAttack.styles'
import { Grid } from '../../atoms/Grid'
import { Title } from '../../atoms/Title'

const WhatDoPanicAttack = () => (
  <Wrapper>
    <section>
      <Grid>
        <TitleWrapper>
          <Title.H2asH1styles>
            Что делать во время панической атаки?
          </Title.H2asH1styles>
        </TitleWrapper>
        <InfoCardsContainer>
          <InfoCard>
            <InfoCardImageHeader>
              <BreatheSVG />
            </InfoCardImageHeader>
            <InfoCardTextContainer>
              <InfoCardTitle>Дышите глубоко и медленно</InfoCardTitle>
              <InfoCardDescription>
                {/* eslint-disable-next-line max-len */}
                Один из самых эффективных способов остановить паническую атаку —
                сфокусироваться на дыхании. Сделайте медленный, глубокий вдох
                через нос и выдох через рот. Сосчитайте до четырех на вдохе,
                задержите дыхание на несколько секунд, а затем медленно
                выдохните. Повторяйте эту схему, пока не почувствуете себя
                спокойнее.
              </InfoCardDescription>
            </InfoCardTextContainer>
          </InfoCard>
          <InfoCard>
            <InfoCardImageHeader>
              <AnxietySVG />
            </InfoCardImageHeader>
            <InfoCardTextContainer>
              <InfoCardTitle>Оцените тревожные мысли</InfoCardTitle>
              <InfoCardDescription>
                {/* eslint-disable-next-line max-len */}
                Панические атаки часто вызываются негативными мыслями или
                убеждениями. Бросьте вызов этим мыслям, спросив себя, являются
                ли они рациональными, основанными на фактах. Например, если у
                вас приступ паники во время вождения, вы можете подумать, что{' '}
                вот-вот разобьетесь. Спросите себя, разумна ли эта мысль, и есть
                ли какие-либо доказательства в её поддержку. Часто вы
                обнаружите, что ваши мысли основаны на страхе, а не на фактах.
              </InfoCardDescription>
            </InfoCardTextContainer>
          </InfoCard>
          <InfoCard>
            <InfoCardImageHeader>
              <GroundingSVG />
            </InfoCardImageHeader>
            <InfoCardTextContainer>
              <InfoCardTitle>Используйте методы заземления</InfoCardTitle>
              <InfoCardDescription>
                {/* eslint-disable-next-line max-len */}
                Техники заземления — упражнения, которые помогут вам
                сосредоточиться на настоящем моменте и отвлечься от тревожных
                мыслей. Вы можете попробовать использовать свои органы чувств,
                чтобы заземлиться: например, понюхать что-то приятное, взять в{' '}
                руки какой-то предмет или сосредоточиться на определенном цвете
                в вашем окружении.
              </InfoCardDescription>
            </InfoCardTextContainer>
          </InfoCard>
          <InfoCardFullSize>
            <InfoCardImageHeader>
              <PreparationSVG />
            </InfoCardImageHeader>
            <InfoCardTextContainer>
              <InfoCardTitle>Подготовьтесь заранее</InfoCardTitle>
              <InfoCardDescription>
                {/* eslint-disable-next-line max-len */}
                Большинство панических атак похожи друг на друга, даже если
                триггеры различаются. Ваши физические ощущения, переживания и
                мысли каждый раз будут примерно одинаковыми, поэтому вы можете
                заранее составить план действий.
                <br />
                {/* eslint-disable-next-line max-len */}
                Например, вы можете в расслабленном состоянии подготовить список
                аргументов против катастрофических мыслей, которые возникают во
                время панической атаки. Подобрать техники, которые помогают
                конкретно вам замедлить сердцебиение, восстановить дыхание и
                снизить тревогу. Или даже заранее записать себе успокаивающее
                голосовое сообщение.
                <br />
                {/* eslint-disable-next-line max-len */}
                Эффективнее всего такой план составлять со специалистом после
                разбора триггеров ваших панических атак и их течения. Но вы
                можете сделать первые шаги самостоятельно с помощью памятки
                “Цикл панических атак”.
              </InfoCardDescription>
            </InfoCardTextContainer>
          </InfoCardFullSize>
        </InfoCardsContainer>
      </Grid>
    </section>
  </Wrapper>
)

export default WhatDoPanicAttack
