import React from 'react'
import rupSVG from './img/rup.svg'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import {
  ButtonWrapper,
  Content,
  IconWrapper,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './WherePsychologistPanicAttack.styles'
import { Grid } from '../../atoms/Grid'

export const RupSVG = styled.img.attrs(() => ({
  src: rupSVG
}))``

const WherePsychologistPanicAttack = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Content>
            <IconWrapper>
              <RupSVG />
            </IconWrapper>
            <TitleWrapper>
              Как найти и выбрать психолога от панических атак?
            </TitleWrapper>
            <TextWrapper>
              {/* eslint-disable-next-line max-len */}
              Вы можете выбрать специалиста самостоятельно из нашего каталога
              или оставить заявку на подбор психолога под ваш запрос нашим
              специалистом по подбору.
            </TextWrapper>
          </Content>
          <ButtonWrapper>
            <Button.NewPrimary href="/wizard/" size="large" type="link">
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonWrapper>
        </Grid>
      </section>
    </Wrapper>
  )
}

export default WherePsychologistPanicAttack
